import React from "react";

// or you can also write
// import htmlContent from 'path/to/html/file.html');

export default function GeoPlicy() {
  // return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  // return <iframe src={htmlContent}></iframe> /* like this */;
  return (
    <div>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="etag" content />
      <meta
        property="og:image"
        content="http://banto.io/static/imgs/img01.png"
      />
      <meta property="og:image:width" content={0} />
      <meta property="og:image:height" content={0} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="<Article Title>" />
      <meta property="og:description" content="<Article Desc>" />
      <meta property="og:url" content="<Article URL>" />
      <meta property="og:see_also" content="<Article Alt URL>" />
      <meta property="og:site_name" content="example.com" />
      <meta property="og:locale" content="en_US" />
      <meta property="fb:app_id" content={816873545350222} />
      <meta property="og:title" content="private info policy" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="http://banto.io/policy/private_info" />
      <meta property="og:image" content />
      <meta property="og:site_name" content="private info policy" />
      <meta
        property="og:description"
        content="This page describes Banto's private info policy"
      />
      <meta name="SKYPE_TOOLBAR" content="SKYPE_TOOLBAR_PARSER_COMPATIBLE" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>개인정보취급방침</title>
      <link rel="stylesheet" href="https://stackedit.io/style.css" />
      <div className="stackedit__html">
        <h3 id="개인정보취급방침">
          <strong>개인정보취급방침</strong>
        </h3>
        <p>
          주식회사 반토(이하 “회사”)는 「정보통신망 이용촉진 및 정보보호 등에
          관한 법률」, 「개인정보 보호법」등 관련 법령상의 개인정보보호 규정을
          준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익
          보호에 최선을 다하고 있습니다. 회사는 개인정보취급방침을 통하여
          이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며,
          개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 회사는
          개인정보취급방침을 개정하는 경우 웹사이트 또는 앱 공지사항(또는
          개별공지)을 통하여 공지할 것입니다. 본 방침은 2019년 2월 3일부터
          시행됩니다.
        </p>
        <p>
          <strong>제 1 조 (개인정보의 수집 및 이용목적)</strong>
        </p>
        <p>
          회사는 개인정보를 회원관리, 서비스 개발·제공 및 향상, 안전한 모바일
          이용환경 등 다음의 목적을 위하여 수집하고 처리합니다. 처리한
          개인정보는 다음의 목적 이외의 용도로는 사용되지 않으며 이용 목적이
          변경될 시에는 사전동의를 구할 것입니다.
        </p>
        <ul>
          <li>
            <p>
              회원 가입 의사의 확인, 개인 식별, 불량 회원의 부정 이용 방지와
              비인가 사용 방지, 가입 및 가입 횟수 제한, 연령확인, 불만 처리 등
              회원관리를 위하여 개인정보를 이용합니다.
            </p>
          </li>
          <li>
            <p>
              서비스제공에 더하여, 인구통계학적 분석, 서비스 방문 및 이용기록의
              분석 등 신규서비스 요소의 발굴 및 기존 서비스 개선 등을 위하여
              개인정보를 이용합니다.
            </p>
          </li>
          <li>
            <p>
              법령 및 회사의 서비스 이용약관을 위반하는 회원에 대한 이용 제한
              조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는
              행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 약관 개정
              등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자
              보호 및 서비스 운영을 위하여 개인정보를 이용합니다.
            </p>
          </li>
          <li>
            <p>
              유료 서비스 제공에 따르는 본인인증, 구매 및 요금 결제를 위하여
              개인정보를 이용합니다.
            </p>
          </li>
          <li>
            <p>
              이벤트 정보 및 참여기회 제공, 광고성 제공 정보 등 마케팅 및
              프로모션 목적으로 개인정보를 이용합니다.
            </p>
          </li>
          <li>
            <p>
              서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스
              분석 및 통계에 따른 맞춤형 서비스 제공 등에 개인정보를 이용합니다.
            </p>
          </li>
          <li>
            <p>
              보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는
              서비스 이용환경 구축을 위해 개인정보를 이용합니다.
            </p>
          </li>
          <li>
            <p>사고발생시 원인 규명 및 처리를 위한 수집 및 이용</p>
          </li>
        </ul>
        <p>
          <strong>제 2조 (수집하는 개인정보의 항목)</strong>
          <br />
          ㅇ<br />
          가. 수집하는 개인정보의 항목
        </p>
        <ol>
          <li>
            회사는 회원 종류에 따라 다음과 같은 개인정보를 필수항목으로 수집하고
            있습니다.
          </li>
        </ol>
        <ul>
          <li>휴대전화번호</li>
        </ul>
        <ol start={2}>
          <li>
            회사는 다음과 같은 회원의 개인정보를 선택항목으로 수집할 수
            있습니다.
          </li>
        </ol>
        <ul>
          <li>프로필 사진, 이름, 성별, 생년월일, 이메일</li>
        </ul>
        <ol start={3}>
          <li>
            서비스 이용과정이나 사업 처리 과정에서 다음과 같은 정보들이 자동으로
            생성되어 수집될 수 있습니다.
          </li>
        </ol>
        <ul>
          <li>
            IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 기기
            정보, 채팅 기록, 앱 설치정보, 네트워크 위치정보 등
          </li>
        </ul>
        <ol start={4}>
          <li>
            서비스 이용과정에서 다음과 같은 결제정보들이 수집될 수 있습니다.
          </li>
        </ol>
        <ul>
          <li>
            <p>신용카드 결제 시: 카드사명, 카드번호 등</p>
          </li>
          <li>
            <p>계좌 이체 시: 은행명, 계좌번호 등</p>
          </li>
          <li>
            <p>상품권 이용 시: 상품권 번호</p>
          </li>
          <li>
            <p>환불 시 : 은행명, 계좌번호 등</p>
          </li>
        </ul>
        <ol start={5}>
          <li>
            회사는 회원의 결제카드 등 서비스 운영상 필요하다고 판단하는 회원의
            정보에 대하여 정기적으로 유효성을 검사하거나 확인할 수 있습니다.
          </li>
        </ol>
        <p>나. 개인정보 수집방법</p>
        <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
        <ul>
          <li>
            <p>
              홈페이지(회원가입, 게시판, 경품 응모, 제휴), 모바일, 서면 양식,
              전화/팩스를 통한 회원가입, 경품 행사 응모, 배송 요청
            </p>
          </li>
          <li>
            <p>생성 정보 수집 툴을 통한 수집</p>
          </li>
        </ul>
        <p>
          <strong>제 3 조 (개인정보의 보유 및 이용기간)</strong>
        </p>
        <p>
          가. 회사는 회원이 회원자격을 유지하고 있는 동안 수집된 회원의
          개인정보를 보유ㆍ이용할 수 있습니다.
        </p>
        <p>
          나. 회원이 탈퇴하거나 자격을 상실할 경우, 회사는 회원의 별도 요청이
          없더라도 수집된 회원정보를 삭제 및 파기합니다. 단, 회원의 탈퇴 또는
          자격상실에도 불구하고 다음의 정보는 아래의 이유로 명시 기간 동안
          보존됩니다.
        </p>
        <ol>
          <li>회사 내부 방침에 의한 정보 보유</li>
        </ol>
        <ul>
          <li>
            <p>탈퇴회원의 재가입 제한을 위한 회원정보 기록: 3개월</p>
          </li>
          <li>
            <p>
              이용 제한 회원 및 부정이용자의 재가입 제한을 위한 회원정보 기록:
              제한기간에 따라 1년 또는 영구(별도저장)
            </p>
          </li>
        </ul>
        <ol start={2}>
          <li>관계법령에 의한 정보 보유 사유</li>
        </ol>
        <ul>
          <li>
            <p>
              표시/광고에 관한 기록 : 6개월 (전자상거래 등에서의 소비자보호에
              관한 법률)
            </p>
          </li>
          <li>
            <p>
              계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래 등에서의
              소비자보호에 관한 법률)
            </p>
          </li>
          <li>
            <p>
              대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래 등에서의
              소비자보호에 관한 법률)
            </p>
          </li>
          <li>
            <p>
              소비자의 불만 또는 분쟁 처리에 관한 기록 : 3년 (전자상거래
              등에서의 소비자보호에 관한 법률)
            </p>
          </li>
          <li>
            <p>
              로그인 기록, 컴퓨터통신 또는 인터넷의 사용자가 정보통신망에
              접속하기 위하여 사용하는 정보통신기기의 위치를 확인할 수 있는
              접속지의 추적자료 : 3개월 (통신비밀보호법)
            </p>
          </li>
          <li>
            <p>전자금융 거래에 관한 기록 : 5년 (전자금융거래법)</p>
          </li>
          <li>
            <p>
              그 외의 통신사실 확인자료(가입자의 전기통신일시,
              통신개시·종료시간, 사용도수, 정보통신망에 접속된 정보통신기기의
              위치를 확인할 수 있는 발신기지국의 위치추적자료) : 각 12개월 보관
            </p>
          </li>
        </ul>
        <p>
          <strong>제 4 조 (동의의 거부권 및 거부 시의 불이익 고지)</strong>
        </p>
        <p>
          회원은 개인정보 수집ㆍ이용에 관한 동의를 거부할 권리가 있습니다. 다만,
          계약 체결 및 이행 등을 위해 필요한 최소한의 개인정보 수집ㆍ이용에 관한
          동의를 거부하는 경우에는 서비스 이용이 불가하고, 마케팅 활동 및 홍보를
          위한 개인정보 수집ㆍ이용 및/또는 선택적 수집ㆍ이용에 관한 동의를
          거부하는 경우에는 이벤트, 혜택에 대한 정보를 제공받지 못하거나
          사은품ㆍ판촉물 제공, 제휴서비스 이용, 할인 혜택 적용 및 포인트 적립
          불가 등의 불이익이 있을 수 있습니다.
        </p>
        <p>
          <strong>제 5 조 (개인정보의 파기 절차 및 방법)</strong>
        </p>
        <p>
          회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당
          정보를 지체 없이 파기합니다. 회사의 개인정보 파기 방법은 다음과
          같습니다.
        </p>
        <p>가. 파기방법</p>
        <ol>
          <li>
            <p>전자 파일 : 복원이 불가능한 방법으로 영구삭제</p>
          </li>
          <li>
            <p>종이 문서 : 파쇄 또는 소각</p>
          </li>
        </ol>
        <p>
          <strong>제 6 조 (회원 및 법정대리인의 권리와 그 행사방법)</strong>
        </p>
        <p>
          가. 회원 및 법정대리인은 회사에 대해 언제든지 다음 각 호의
          개인정보보호 관련 권리를 행사할 수 있으며, 등록되어 있는 본인 혹은
          대리자의 개인정보에 대한 열람, 정정, 삭제 및 처리 정지를 요청할 수
          있습니다.
        </p>
        <ul>
          <li>
            <p>개인 정보 열람 요구</p>
          </li>
          <li>
            <p>오류 등이 있을 경우 정정 요구</p>
          </li>
          <li>
            <p>삭제요구</p>
          </li>
          <li>
            <p>처리정지 요구</p>
          </li>
        </ul>
        <p>
          나. “가”항에 따른 권리 행사는 서면 또는 이메일로 하실 수 있으며 회사는
          이에 대해 지체 없이 조치하고 처리결과를 서면 또는 이메일, 문자 전송의
          방법으로 통지합니다.
        </p>
        <p>
          다. 회원이 개인정보의 오류에 대한 정정을 요청한 경우, 회사는 당해
          정정을 완료하기 전까지 당해 개인정보를 이용하거나 제3자에게 제공하지
          않습니다. 또한 잘못된 개인정보를 제3자에게 제공한 경우에는 정정 처리
          결과를 제3자에게 지체 없이 통지합니다.
        </p>
        <p>
          라. 회원이 개인정보의 삭제를 요청한 경우 제3조의 개인정보의 처리 및
          보유기간과 제5조의 개인정보의 파기 절차 및 파기 방법에 명시된 바에
          따릅니다.
        </p>
        <p>
          <strong>
            제 7 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)
          </strong>
        </p>
        <p>
          회사는 회원의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을
          운용합니다. 쿠키란 회사의 웹사이트를 운영하는데 이용되는 서버가 회원의
          브라우저에 보내는 아주 작은 텍스트 파일로서 회원의 컴퓨터 하드디스크에
          저장됩니다. 쿠키의 사용 목적 및 쿠키 설정 거부 방법은 다음과 같습니다.
        </p>
        <p>가. 쿠키 등 사용 목적</p>
        <p>
          회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 회원의 취향과
          관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
          등을 통한 타겟 마케팅 및 개인 맞춤 서비스를 제공하기 위해 사용합니다.
        </p>
        <p>나. 쿠키 설정 거부 방법</p>
        <ol>
          <li>
            <p>
              회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은
              웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
              저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할
              수도 있습니다.
            </p>
          </li>
          <li>
            <p>
              쿠키 설치 허용 여부를 설정하는 방법(인터넷 익스플로러의 경우)은
              다음과 같습니다.
            </p>
          </li>
        </ol>
        <ul>
          <li>웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보</li>
        </ul>
        <ol start={3}>
          <li>
            단, 회원이 쿠키 설치를 거부하였을 경우 기술 지원 및 서비스 제공에
            어려움이 있을 수 있으며, 이 경우 회사는 책임을 지지 않습니다.
          </li>
        </ol>
        <p>
          <strong>제 8 조 (개인정보의 제3자 제공)</strong>
        </p>
        <p>
          회사는 이용자의 개인정보를 “1. 개인정보의 수집 및 이용 목적”, “2.
          수집하는 개인정보 항목” 내에서만 처리하며, 회원의 사전 동의 없이는
          동의 범위를 초과하여 이용하거나 원칙적으로 회원의 개인정보를 외부에
          제공하지 않습니다. 다만, 다음의 경우 주의를 기울여 회원의 개인정보를
          이용 및 제공할 수 있습니다.
        </p>
        <ul>
          <li>
            <p>회원이 동의한 경우</p>
          </li>
          <li>
            <p>
              법령의 규정에 의하여 회사에 회원의 개인정보 제출의무가 발생한 경우
            </p>
          </li>
        </ul>
        <p>
          <strong>제 9 조 (개인정보처리의 위탁)</strong>
        </p>
        <p>
          회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
          처리업무를 위탁하고 있습니다.
        </p>
        <p>
          회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에 위탁하고
          있으며, 위탁계약 체결시 개인정보보호법 제26조에 따라 위탁업무 수행목적
          외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에
          대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
          명시하고, 수탁자가 정보통신망법에 따라 개인정보를 안전하게 처리하도록
          필요한 사항을 규정하고 관리·감독합니다.
        </p>
        <p>
          <strong>수탁자</strong>
        </p>
        <p>Google LLC</p>
        <p>
          <strong>위탁업무 내용 및 목적</strong>
        </p>
        <p>결제처리(신용카드, 환불계좌 인증) 및 결제 도용 방지</p>
        <p>
          <strong>제 10 조 (개인정보의 안전성 확보 조치)</strong>
        </p>
        <p>
          회사는 회원의 개인정보를 안전하게 관리하기 위하여 최선을 다하며,
          정보통신망법 및 개인정보보호법에서 요구하는 수준 이상으로 개인정보를
          보호하기 위하여 다음과 같은 조치를 취하고 있습니다.
        </p>
        <ol>
          <li>
            <p>
              관리적 조치: 개인정보 관리책임자의 지정 등 개인정보보호 조직의
              구성, 개인정보 취급자를 최소한으로 제한, 운영에 관한 사항,
              개인정보취급자의 교육에 관한 사항을 포함하는 내부관리계획의 수립
              및 시행
            </p>
          </li>
          <li>
            <p>
              기술적 조치: 개인정보를 처리할 수 있도록 체계적으로 구성한
              데이터베이스시스템에 대한 접근권한의 부여/변경/말소 등에 관한
              기준의 수립/시행, 개인정보처리시스템에 대한 침입차단시스템 및
              침입탐지시스템의 설치/운영, 개인정보취급자가 개인정보처리시스템에
              접속하여 개인정보를 처리한 경우 접속일시, 처리내역 등의 저장 및
              이의 확인/감독, 비밀번호의 일방향 암호화 저장, 주민등록번호,
              외국인인증번호, 계좌정보, 신용카드정보 등 금융정보의 암호화 저장,
              정보통신망을 통하여 이용자의 개인정보 및 인증정보를 송신/수신하는
              경우 암호화 보안 통신 이용, 개인정보처리시스템 및 개인정보취급자가
              개인정보 처리에 이용하는 정보기기에 컴퓨터바이러스, 스파이웨어 등
              악성프로그램의 침투 여부를 항시 점검/치료할 수 있도록
              백신소프트웨어 설치 및 주기적 갱신 및 점검
            </p>
          </li>
        </ol>
        <p>
          <strong>
            제 11 조 (개인정보보호 업무 관련 담당부서 및 개인정보 관리책임자)
          </strong>
        </p>
        <p>
          회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
          위하여 아래와 같이 관련 부서 및 개인정보 관리책임자를 지정하고
          있습니다. 회원은 회사의 서비스를 이용하며 발생하는 모든 개인정보보호
          관련 민원을 개인정보관리책임자 혹은 담당부서로 신고할 수 있습니다.
          회사는 회원들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
        </p>
        <p>
          고객서비스담당부서 | 주식회사 반토 고객센터 | 이메일:{" "}
          <a href="mailto:morenicejay@gmail.com">morenicejay@gmail.com</a>
        </p>
        <p>
          개인정보관리책임자 | 성명: 손승환 | 이메일:{" "}
          <a href="mailto:lmk378@gmail.com">lmk378@gmail.com</a>
        </p>
        <p>
          기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
          문의하시기 바랍니다.
        </p>
        <p>
          개인정보침해신고센터 (
          <a href="http://www.1336.or.kr/%EA%B5%AD%EB%B2%88%EC%97%86%EC%9D%B4">
            http://www.1336.or.kr/국번없이
          </a>{" "}
          118)
        </p>
        <p>
          정보보호마크인증위원회 (
          <a href="http://www.eprivacy.or.kr/02-580-0533~4">
            http://www.eprivacy.or.kr/02-580-0533~4
          </a>
          )
        </p>
        <p>
          대검찰청 첨단범죄수사과 (
          <a href="http://spo.go.kr/02-3480-2000">
            http://spo.go.kr/02-3480-2000
          </a>
          )
        </p>
        <p>
          경찰청 사이버테러대응센터 (
          <a href="http://www.ctrc.go.kr/02-392-0330">
            http://www.ctrc.go.kr/02-392-0330
          </a>
          )
        </p>
        <p>
          <strong>제 12 조 (고지 의무)</strong>
        </p>
        <p>
          현 개인정보취급방침에 대하여 내용 추가, 삭제 및 수정이 있을 시에는
          개정 최소 7일전부터 '공지사항’을 통해 고지합니다. 다만, 개인정보의
          수집 및 활용, 제3자 제공 등과 같이 회원 권리의 중요한 변경이 있을
          경우에는 최소 30일 전에 고지합니다.
        </p>
        <ul>
          <li>
            <p>공고일자 : 2019년 2월 3일</p>
          </li>
          <li>
            <p>시행일자 : 2019년 2월 3일</p>
          </li>
        </ul>
      </div>
    </div>
  );
}
